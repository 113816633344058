import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { navigate } from "@reach/router";
import Layout from "../../components/Layout";
import Content, { HTMLContent } from "../../components/Content";

// share
import SharePartsOfTroublePagePreview from "./../share-parts-of-trouble-page";
import SharePlacesPageTemplate from "./../share-places-page";
import SharePickupNewsPageTemplate from "./../share-pickup-news-page";
import ShareHistoryPageTemplate from "./../share-history-page";
import ShareInfomationPageTemplate from "./../share-infomation-page";
import { DullnessRankingPageTemplate } from "../dullness/dullness-ranking-page";

// data
import iontophoresisData from "./../../data/machines/iontophoresis.json";

const onPressToMoveScreenWithQuery = hash => {
  navigate(`/beauty-medical-treatment/price${hash}`);
};

export const IontophoresisPageTemplate = ({
  title,
  content,
  contentComponent
}) => {
  return (
    <>
      <div className="c-bread">
        <div className="c-bread__inner">
          <div className="c-bread__item">
            <Link className="c-bread__link" to={"/"}>
              <div className="c-bread__text">トップ</div>
            </Link>
          </div>
          <div className="c-bread__item">
            <div className="c-bread__text">
              {iontophoresisData[0] && iontophoresisData[0].title.length
                ? iontophoresisData[0].title
                : null}
            </div>
          </div>
        </div>
      </div>

      <section className="l-section-lv2 l-main-media-hero">
        <div className="l-main-media-hero__inner">
          <div className="c-simple-title -small">
            <h2 className="c-simple-title__text">
              {iontophoresisData[0] && iontophoresisData[0].title.length
                ? iontophoresisData[0].title
                : null}
            </h2>
          </div>

          {iontophoresisData[0] && iontophoresisData[0].duration.length
            ? iontophoresisData[0].duration.map((item, index) => {
                return (
                  <>
                    <section>
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>

                      <div className="c-tile2">
                        <div className="c-tile2__inner">
                          <div className="c-tile2__head">
                            {item &&
                            item.time_table[index].table_title[index].text
                              .length
                              ? item.time_table[index].table_title.map(
                                  tabelItem => {
                                    return (
                                      <div className="c-tile2__head__item">
                                        <p className="c-tile2__head__text">
                                          {tabelItem.text}
                                        </p>
                                      </div>
                                    );
                                  }
                                )
                              : null}
                          </div>
                          <div className="c-tile2__body">
                            <div className="c-tile2__body__row">
                              {item &&
                              item.time_table[index].table_contents[index].text
                                .length
                                ? item.time_table[index].table_contents.map(
                                    contentsItem => {
                                      return (
                                        <div className="c-tile2__body__item">
                                          <p className="c-tile2__body__text">
                                            {contentsItem.text}
                                          </p>
                                        </div>
                                      );
                                    }
                                  )
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      {item && item.caution.length
                        ? item.caution.map(item => {
                            return (
                              <div className="c-text u-pc-mt20 u-sp-mt10">
                                {item.text}
                              </div>
                            );
                          })
                        : null}
                    </section>
                  </>
                );
              })
            : null}

          {iontophoresisData[0] && iontophoresisData[0].expect_actions.length
            ? iontophoresisData[0].expect_actions.map(item => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>

                      <div className="c-tile3">
                        <div className="c-tile3__inner">
                          <div className="c-tile3__body">
                            <div className="c-tile3__body__row">
                              {item.lables.map(item => {
                                return (
                                  <div className="c-tile3__body__item">
                                    <p className="c-tile3__body__text">
                                      {item.text}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                );
              })
            : null}

          {iontophoresisData[0] &&
          iontophoresisData[0].operating_description.length
            ? iontophoresisData[0].operating_description.map(item => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>

                      <ul className="c-text-list">
                        {item.contents.map(item => {
                          return (
                            <li className="c-text-list__item">{item.text}</li>
                          );
                        })}
                      </ul>
                    </section>
                  </>
                );
              })
            : null}

          {iontophoresisData[0] && iontophoresisData[0].kind_of_beauty.length
            ? iontophoresisData[0].kind_of_beauty.map(item => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>
                      {item.contents.map(item => {
                        return (
                          <dl className="c-dl-list">
                            <dt className="c-dl-list__title">
                              {item.title}
                              <span className="u-sp-dis-n u-pc-ml10">…</span>
                            </dt>
                            <dd className="c-dl-list__text">{item.text}</dd>
                          </dl>
                        );
                      })}
                    </section>
                  </>
                );
              })
            : null}

          {iontophoresisData[0] &&
          iontophoresisData[0].treatment_interval.length
            ? iontophoresisData[0].treatment_interval.map(item => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>

                      <ul className="c-text-list">
                        {item.contents.map(item => {
                          return (
                            <li className="c-text-list__item">{item.text}</li>
                          );
                        })}
                      </ul>
                    </section>
                  </>
                );
              })
            : null}

          {iontophoresisData[0] && iontophoresisData[0].flow_treatment.length
            ? iontophoresisData[0].flow_treatment.map(item => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>

                      <div className="c-flow-treatment">
                        <div className="c-flow-treatment__inner">
                          {item.contents.map((item, index) => {
                            return (
                              <div className="c-flow-treatment__item">
                                <div className="c-flow-treatment__title">
                                  {item.title}
                                </div>
                                <p className="c-flow-treatment__description">
                                  {item.description}
                                </p>
                                <div className="c-flow-treatment__circle"></div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </section>
                  </>
                );
              })
            : null}

          {iontophoresisData[0] && iontophoresisData[0].treatment_pain.length
            ? iontophoresisData[0].treatment_pain.map(item => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>

                      <ul className="c-text-list">
                        {item.contents.map(item => {
                          return (
                            <li className="c-text-list__item">{item.text}</li>
                          );
                        })}
                      </ul>
                    </section>
                  </>
                );
              })
            : null}

          {iontophoresisData[0] && iontophoresisData[0].downtime.length
            ? iontophoresisData[0].downtime.map(item => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>

                      <ul className="c-text-list">
                        {item.contents.map(item => {
                          return (
                            <li className="c-text-list__item">{item.text}</li>
                          );
                        })}
                      </ul>
                    </section>
                  </>
                );
              })
            : null}

          {iontophoresisData[0] && iontophoresisData[0].aftercare.length
            ? iontophoresisData[0].aftercare.map(item => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>

                      <ul className="c-text-list">
                        {item.contents.map(item => {
                          return (
                            <li className="c-text-list__item">{item.text}</li>
                          );
                        })}
                      </ul>
                    </section>
                  </>
                );
              })
            : null}

          {iontophoresisData[0] && iontophoresisData[0].precaution.length
            ? iontophoresisData[0].precaution.map(item => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>

                      <ul className="c-text-list">
                        {item.contents.map(item => {
                          return (
                            <li className="c-text-list__item">{item.text}</li>
                          );
                        })}
                      </ul>
                    </section>
                  </>
                );
              })
            : null}

          <div className="u-txt-c u-pc-mt100 u-sp-mt60">
            <div
              className="c-border-button -arrow"
              onClick={() =>
                onPressToMoveScreenWithQuery("#female-iontophoresis-bitamin-c")
              }
            >
              <p className="c-border-button__text">料金を確認</p>
            </div>
          </div>

          {typeof window !== "undefined" &&
            window.location.hash === "#prev-dullness-screen" && (
              <>
                <div className="c-simple-title -small u-pc-mt100 u-sp-mt50">
                  <h2 className="c-simple-title__text">
                    肝斑、くすみ施術ランキング
                  </h2>
                </div>

                <DullnessRankingPageTemplate />
              </>
            )}
        </div>
      </section>
    </>
  );
};

IontophoresisPageTemplate.propTypes = {
  title: PropTypes.string
};

const IontophoresisPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <IontophoresisPageTemplate title={post.frontmatter.title} />
      <SharePartsOfTroublePagePreview />
      <SharePlacesPageTemplate />
      <SharePickupNewsPageTemplate />
      <ShareHistoryPageTemplate />
      <ShareInfomationPageTemplate />
    </Layout>
  );
};

IontophoresisPage.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string
};

export default IontophoresisPage;

export const iontophoresisPageQuery = graphql`
  query IontophoresisPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
